/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Table from '../../../components/Table';
import campaignSubmissionsTableDataRenderer from './campaignSubmissionsTableDataRenderer';
import {Container, Row, Column, Section} from '../../../layout/Grid';
import lang from '../../../../lang/pages/aktionsteilnahmen.lang';
import Pagination from '../../../components/Pagination';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {
    selectSubmissions,
    loadSubmissions,
    selectCurrentPage,
    selectTotalPages,
} from '../../../../model/campaign-submissions';

export const CampaignSubmissionsTable = ({
    theme,
    table,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    const handleButtonClick = (page) => {
        if (currentPage !== page) {
            onPageChange(page);
        }
    };
    return (
        <Section theme={theme}>
            <Container width="medium">
                <Row>
                    <Column xs={12}>
                        <Table
                            theme={theme}
                            cols={lang.tableHeadings}
                            rows={table}
                            dataRenderer={campaignSubmissionsTableDataRenderer}
                        />
                    </Column>
                    <Column xs={12}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleButtonClick={handleButtonClick}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

CampaignSubmissionsTable.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    table: PropTypes.arrayOf(
        PropTypes.shape({
            campaign: PropTypes.string,
            status: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            username: PropTypes.string,
            pharmacy: PropTypes.string,
        })
    ),
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func,
};

CampaignSubmissionsTable.defaultProps = {
    theme: 'default',
    table: [],
    reasons: [],
};

const ConnectedCampaignSubmissionsTable = (props) => {
    const submissions = useSelector(selectSubmissions);
    const currentPage = useSelector(selectCurrentPage);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadSubmissions(currentPage, false));
    }, [dispatch]);

    const handlePageChange = (page) => {
        dispatch(loadSubmissions(page, false));
    };
    return (
        <CampaignSubmissionsTable
            {...props}
            table={submissions}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
        />
    );
};

export default ConnectedCampaignSubmissionsTable;
