import React from 'react';

import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import lang from '../lang/pages/aktionsteilnahmen.lang';
import CampaignSubmissionsTable from '../ui/domains/sales-agent-user-interactions/CampaignSubmissionsTable';

const Aktionsteilnahmen = () => {
    return (
        <Layout>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.headline}</Headline>
                    <Paragraph>{lang.paragraph}</Paragraph>
                </Container>
            </Section>
            <CampaignSubmissionsTable theme="blue" />
        </Layout>
    );
};

export default Aktionsteilnahmen;
