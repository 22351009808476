import PropTypes from 'prop-types';
import React, {useState} from 'react';

import Icon from '../../../../components/Icon';
import Lightbox from '../../../../components/Lightbox';
import {color} from '../../../../../styles/variables';

import SubmissionForm from '../SubmissionForm';

export default function ThumbsItem({submissionId}) {
    const [isLightbox, setIsLightbox] = useState(false);
    const [isRejected, setIsRejected] = useState(null);

    return (
        <>
            <div
                onClick={() => {
                    setIsRejected(false);
                    setIsLightbox(true);
                }}>
                <Icon name="thumbUp" color={color.grey} size="1.5rem" />
                <span>an&shy;nehmen</span>
            </div>
            <div
                onClick={() => {
                    setIsRejected(true);
                    setIsLightbox(true);
                }}>
                <Icon name="thumbDown" color={color.grey} size="1.5rem" />
                <span>ab&shy;lehnen</span>
            </div>
            <Lightbox
                maxWidth="25rem"
                isOpen={isLightbox}
                onClose={() => setIsLightbox(false)}>
                <SubmissionForm
                    theme="blue"
                    isRejected={isRejected}
                    submissionId={submissionId}
                    setIsLightbox={setIsLightbox}
                />
            </Lightbox>
        </>
    );
}

ThumbsItem.propTypes = {
    submissionId: PropTypes.number,
};
