import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {reasonSubmissionEvaluationValidator} from '../../../../../utils/form-validation';
import Headline from '../../../../components/Headline';
import Paragraph from '../../../../components/Paragraph';
import Button from '../../../../components/Button';
import Link from '../../../../components/Link';
import Form, {Select} from '../../../../components/Form';
import {Textarea} from '../../../../components/Form';
import {Container, Row, Column, Section} from '../../../../layout/Grid';
import {ReactComponent as StarsSvg} from '../../../../../assets/images/trainings/img-overlay--punkte.svg';
import lang from '../../../../../lang/pages/aktionsteilnahmen.lang';

import {evaluateSubmission} from '../../../../../model/campaign-submissions';

const defaultValues = {
    message: null,
    reason: '',
};
export function SubmissionForm({
    onSubmit,
    theme,
    isRejected,
    submissionId,
    setIsLightbox,
}) {
    const statusLang = isRejected ? lang.rejectForm : lang.acceptForm;

    const validationSchema = yup.object().shape({
        reason: isRejected ? reasonSubmissionEvaluationValidator : null,
    });

    const {control, handleSubmit, errors} = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const tranformDataForSubmit = (data) => {
        const changeData = {
            id: submissionId,
            isRejected,
            reason: isRejected ? data.reason.value : null,
            message: data.message,
        };
        onSubmit(changeData);
    };

    return (
        <Form theme={theme} onSubmit={handleSubmit(tranformDataForSubmit)}>
            <Section theme={theme} noPadding>
                <Container width="narrow">
                    <Row>
                        <Column xs={12} className="text-center">
                            <StarsSvg />
                            <Headline type="h5" fontWeight="bold">
                                {statusLang.headline}
                            </Headline>
                            <Paragraph>{statusLang.paragraph}</Paragraph>
                        </Column>
                        {isRejected && (
                            <Column xs={12}>
                                <Select
                                    label={statusLang.select.label}
                                    name={statusLang.select.name}
                                    options={statusLang.options}
                                    placeholder={statusLang.select.placeholder}
                                    control={control}
                                    errors={errors}
                                    sectionTheme={theme}
                                />
                            </Column>
                        )}
                        <Column xs={12}>
                            <Textarea
                                placeholder={statusLang.message.placeholder}
                                label={statusLang.message.label}
                                name={statusLang.message.name}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column xs={12} className="text-center">
                            <Button
                                type="submit"
                                label={statusLang.submitButton}
                            />
                        </Column>
                        <Column xs={12} className="text-center">
                            <Link href="#" onClick={() => setIsLightbox(false)}>
                                {statusLang.cancelLink}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Section>
        </Form>
    );
}

SubmissionForm.propTypes = {
    onSubmit: PropTypes.func,
    reason: PropTypes.string,
    setIsForm: PropTypes.func,
    theme: PropTypes.string,
    isRejected: PropTypes.bool,
    submissionId: PropTypes.number,
    setIsLightbox: PropTypes.func,
};

SubmissionForm.defaultProps = {
    onSubmit: () => {},
    setIsLightbox: () => {},
    theme: '',
    reasons: [],
    submissionId: null,
};

const ConnectedSubmissionForm = (props) => {
    const dispatch = useDispatch();

    const handleSubmit = (data) => {
        dispatch(evaluateSubmission(data));
    };

    return <SubmissionForm {...props} onSubmit={handleSubmit} />;
};

export default ConnectedSubmissionForm;
